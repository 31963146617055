<script>
import appConfig from '@/app.config'
import store from '@/state/store'
import Storage from '../../../services/storage'

export default {
    page: {
        title: 'change number',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    data() {
        return {
            rules: {
                email: [
                    {
                        required: true, message: 'Email is required.', trigger: 'change'
                    },
                    {
                        type: 'email', message: 'Enter a valid email address.', trigger: 'change'
                    },
                    { min: 10, max: 50, message: `Length should be greater than 10`, trigger: 'blur' }
                ],
            },
            formData: {
                email: '',
            },
            fullscreenLoading: false,
            errors: '',
            loading: false,
            userEmail: '',
        }
    },
    computed: {
        currentUser() {
            return store.getters['auth/currentUser'] || { email: 'No Email Address' }
        },
        CurrentUserEmail() {
            return this.userEmail
        }
    },
    methods: {
        submitForm(formName) {
            this.errors = '';
            this.loading = true
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.fullscreenLoading = true
                    store.dispatch(
                        'auth/sendEmailForVerification',
                        {
                            email: this.formData.email,
                            user_id: this.currentUser.user_id
                        }
                    ).then((response) => {
                        this.loading = false
                        if (response.status === 200) {
                            this.$notify({
                                message: 'Email changed successfully',
                                type: 'success'
                            });
                            sessionStorage.setItem('emailVerified', "false")
                            localStorage.setItem('verificationFromProfile', "true")
                            this.userEmail = this.formData.email
                            // when email is already verified it says true instead of throwing an error
                            this.$router.push({ name: 'profile' })
                        }
                        else {
                            this.$message({
                                message: response.data.message,
                                type: 'error'
                            });
                        }
                    }
                    ).catch((error) => {
                        this.loading = false
                        this.$message({
                            message: error.response.data.data.error,
                            type: 'error'
                        });
                    })
                        .finally(() => {
                            this.fullscreenLoading = false
                        })
                }
                else {
                    this.loading = false

                }
            });
        }
    },
    mounted() {
        // Analytics
        this.$mixpanel.track("Change Email Page")
        if (this.$store.getters["auth/currentUser"].country === "CI") {
            this.$mixpanelFranco.track("Change Email Page")
        }
        store.dispatch('auth/viewIndividualProfile').then((data) => {
            this.userEmail = data.data.data.email
        })
    }
}
</script>
<style scoped>
#account-settings {
    height: calc(100vh - 120px);
}

.profile_form {
    width: 385px;
    margin-left: 3em;
}

@media screen and (max-width: 500px) {
    .profile_form {
        width: 100%;
        ;
        margin-left: 0px;
    }
}

.warning {
    color: red
}

.contains-new-input {
    width: 100%;
}

.is-form-item {
    display: block;
}

.input-model {
    min-width: 100% !important;
}
</style>
<template>
    <div class="col-md-12" v-loading.fullscreen.lock="fullscreenLoading">
        <div id="account-settings" class="mnotify-card">
            <div id="side-indicators-content" class="mnotify-card-header">
                <div class="profile-settings-section-header">
                    <profilesettingsBtn />
                    <div class="profile-settings-sub-header">
                        <h3 class="mnotify-card-header-text pb-2 my-0">{{ $t("dashboard.account_settings.change_email.title") }} </h3>
                        <p class="profile-description">
                            {{ $t("dashboard.account_settings.change_email.sub_header") }} </p>
                    </div>
                </div>
            </div>
            <div class="mnotify-card-body">
                <div class="row profile-settings-form" style="margin-top:2em;">
                    <div class="profile_form">
                        <p class="profile-see-more warning">
                            {{ $t("dashboard.account_settings.change_email.note") }} 
                            </p>
                        <div class="profile-inner">
                            <el-form :model="formData" :rules="rules" ref="ruleForm" @submit.prevent.native="checkEnter">
                                <div class="form_input_container">
                                    <el-form-item 
                                    :label="$t('dashboard.account_settings.change_email.form.current_email.label')"
                                    >
                                        <el-input type="text" autocomplete="off" :placeholder="$t('dashboard.account_settings.change_email.form.current_email.placeholder')"
                                            v-model="CurrentUserEmail" disabled></el-input>
                                    </el-form-item>
                                </div>
                                <div class="form_input_container contains-new-input">
                                    <el-form-item :label=" $t('dashboard.account_settings.change_email.form.new_email.label')"
                                    prop="email" class="is-form-item">
                                        <el-input type="text" autocomplete="off" :placeholder="$t('dashboard.account_settings.change_email.form.new_email.placeholder')"
                                            v-model="formData.email"></el-input>

                                    </el-form-item>
                                </div>
                                <Alert v-if="errors" :message="errors" :type="type" />
                                <div class="terms_and_conditons_container mt-5">
                                    <el-form-item>
                                        <el-button id="mNotify_form_submit_btn" :loading="loading" type="primary"
                                            @click="submitForm('ruleForm')">
                                            {{ $t("dashboard.account_settings.change_email.form.button") }}</el-button>
                                    </el-form-item>
                                </div>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
